<template lang="pug">
  v-card
      v-card-title
        h2.card-title {{ 'modals.reports.batch_info.title' | translate }}
      v-card-content
        table.table
          thead.table__header
            tr.table__row.table__row--header
              td.table__cell {{ 'base.ingredients' | translate }}
              td.table__cell {{ 'base.weight' | translate }} [{{ 'units.kg' | translate }}] #[br] {{ 'base.plan' | translate }} / {{ 'base.fact' | translate }}
              td.table__cell {{ 'pages.reports.deviation' | translate }} [% / {{ 'units.kg' | translate }}]
              td.table__cell(v-html="$t('pages.reports.load_start_br')")
              td.table__cell(v-html="$t('pages.reports.load_time_br')")
              td.table__cell {{ 'base.operator' | translate }}
          tbody.table__body
            tr.table__row.table__row--body(
              v-for="item in item.ingredients"
              :key="item.id")
                td.table__cell {{ item.ingredient_name }}
                td.table__cell {{ item.plan_weight || 0 | rounding }} / {{ item.fact_weight || 0 | rounding }}
                td.table__cell {{ item.rate_deviation || 0 | rounding }} / {{ item.weight_deviation || 0 | rounding }}
                td.table__cell {{ item.time }}
                td.table__cell {{ item.loaded_time }}
                td.table__cell {{ item.user }}
  </template>

<script>
export default {
  name: 'BatchInfoModal',
  props: {
    item: Object
  }
}

</script>

<style scoped lang="scss">
.table__cell {
  font-size: 1.3rem;
}
</style>
